
export class Display {

    /**
     * ISO8601形式の日時から、日付部分を取得する
     * @param iso8601string 
     */
    public static getDateStringFromISO8601(iso8601string: string | undefined): string {
        if (iso8601string === undefined) {
            return "未定義";
        } else {
            return iso8601string.split("T")[0];
        }
    }

    /**
     * ISO8601形式の日時から、時間と分を取得する
     * @param iso8601string 
     */
    public static getTimeStringFromISO8601(iso8601string: string | undefined): string {
        if (iso8601string === undefined) {
            return "未定義";
        } else {
            const time = iso8601string.split("T")[1].split(".")[0];
            return time.split(":")[0] + ":" + time.split(":")[1];
        }
    }

    /**
     * ISO8601形式の日時から、日付と時間と分を取得する
     * @param iso8601string 
     */
    public static getDateTimeStringFromISO8601(iso8601string: string): string {
        const date = iso8601string.split("T")[0];
        const time = iso8601string.split("T")[1].split(".")[0];
        return date + " " + time.split(":")[0] + ":" + time.split(":")[1];
    };

    /**
     * ISO8601形式の日時から、時・分・秒を取得する
     * @param iso8601string 
     */
    public static getTimeMiniteSecondStringFromISO8601(iso8601string: string | undefined): string {
        if (iso8601string === undefined) {
            return "未定義";
        } else {
            const time = iso8601string.split("T")[1].split(".")[0];
            return time.split(":")[0] + ":" + time.split(":")[1] + ":" + time.split(":")[2];
        }
    }


    /**
     * ISO8601形式の日時から、時間と分を取得する
     * @param iso8601string 
     */
    public static getTimeStringFromMINEBEA(timstamp: string | undefined): string {
        if (timstamp === undefined) {
            return "未定義";
        } else {
            return timstamp.split(" ")[1].split(".")[0];
        }
    }

    /**
    * 指定日の文字列をyyyy-mm-ddの形式で取得し、前日に変換する
    */
    public static getPreviousDayWithString(targetDate: string): string {
        var date = new Date(targetDate);
        date.setDate(date.getDate() - 1);
        var year = date.getFullYear();
        var numberMonth = date.getMonth() + 1;
        var month = numberMonth < 10 ? String("0" + numberMonth) : String(numberMonth);
        var numberDate = date.getDate();
        var day = numberDate < 10 ? String("0" + numberDate) : String(numberDate);
        var result = year + "-" + month + "-" + day;
        return result;
    }

    /**
     * 現在日付をyyyy-mm-ddの形式で取得
     */
    public static getNowDateWithString(): string {
        var date = new Date();
        var year = date.getFullYear();
        var numberMonth = date.getMonth() + 1;
        var month = numberMonth < 10 ? String("0" + numberMonth) : String(numberMonth);
        var numberDate = date.getDate();
        var day = numberDate < 10 ? String("0" + numberDate) : String(numberDate);
        var result = year + "-" + month + "-" + day;
        return result;
    }

    /**
    * 指定日の文字列をyyyy-mm-ddの形式で取得し、翌日に変換する
    */
    public static getNextDateWithString(targetDate: string): string {
        var date = new Date(targetDate);
        date.setDate(date.getDate() + 1);
        var year = date.getFullYear();
        var numberMonth = date.getMonth() + 1;
        var month = numberMonth < 10 ? String("0" + numberMonth) : String(numberMonth);
        var numberDate = date.getDate();
        var day = numberDate < 10 ? String("0" + numberDate) : String(numberDate);
        var result = year + "-" + month + "-" + day;
        return result;
    }


    /**
     * JSTを文字列形式（ISO8601）で取得
     */
    public static getJSTToString(val: string): string {
        const date = new Date();
        const dateStringList = val.split("-");
        date.setFullYear(Number.parseInt(dateStringList[0]));
        date.setMonth(Number.parseInt(dateStringList[1]) - 1);
        date.setDate(Number.parseInt(dateStringList[2]));
        date.setHours(9);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);
        return date.toISOString().split('Z')[0] + '+09:00';
    }

    /**
     * 現在時刻を文字列形式で取得
     */
    public static getNowJSTToString(): string {
        const date = new Date();
        const nowDate = date.getFullYear()
            + '/' + ('0' + (date.getMonth() + 1)).slice(-2)
            + '/' + ('0' + date.getDate()).slice(-2)
            + ' ' + ('0' + date.getHours()).slice(-2)
            + ':' + ('0' + date.getMinutes()).slice(-2)
            + ':' + ('0' + date.getSeconds()).slice(-2)
            + '(JST)'
        return nowDate;
    }

    /**
     * 現在時刻HHMMSSを文字列形式で取得
     */
    public static getNowJSTTimeToString(): string {
        const date = new Date();
        const nowDate = ('0' + date.getHours()).slice(-2)
            + ':' + ('0' + date.getMinutes()).slice(-2)
            + ':' + ('0' + date.getSeconds()).slice(-2)
        return nowDate;
    }

    /**
     * date1とdate2を比較して同じかを確認する
     * @return date1 === date2の場合true、それ以外はfalse
     */
    public static isDay1AndDay2TheSame(date1: Date, date2: Date): boolean {
        var year1 = date1.getFullYear();
        var month1 = date1.getMonth() + 1;
        var day1 = date1.getDate();

        var year2 = date2.getFullYear();
        var month2 = date2.getMonth() + 1;
        var day2 = date2.getDate();

        return year1 === year2 && month1 === month2 && day1 === day2;
    }

    public static checkPrefDeliveryDate(dateString: string | undefined): boolean {
        if (typeof dateString === 'undefined') {
            return false;
        }

        if (RegExp(/^\d{4}-\d{2}-\d{2}$/).test(dateString)) {
            let dateStringList = dateString.split('-');
            let dateList = dateStringList.map((value) => {
                return Number(value);
            });
            let dateFromArg = new Date(dateList[0], dateList[1] - 1, dateList[2]);
            let today = new Date();
            today.setHours(0, 0, 0, 0);
            return dateFromArg.getTime() >= today.getTime();
        } else {
            return false;
        }
    }
}