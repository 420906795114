/**
 * 
 */
export const ReasonCode = {
    AAA0001: "AAA0001",
    AAA0002: "AAA0002",
    AAB0001: "AAB0001",
    AAB0002: "AAB0002",
    AAC0001: "AAC0001",
    AAD0001: "AAD0001",
    AAD0002: "AAD0002",
    AAD0003: "AAD0003",
    AAD0004: "AAD0004",
    AAD0005: "AAD0005",
    AAE0001: "AAE0001",
    AAE0002: "AAE0002",
    AAF0001: "AAF0001",
    AAG0001: "AAG0001",
    AAG0002: "AAG0002",
    AAH0001: "AAH0001",
    AAH0002: "AAH0002",
    AAI0001: "AAI0001",
    AAI0002: "AAI0002",
    AAJ0001: "AAJ0001",
    AAJ0002: "AAJ0002",
    AAK0001: "AAK0001",
    AAK0002: "AAK0002",
    BBF0001: "BBF0001",
    BBF0002: "BBF0002",
    BBF0003: "BBF0003",
    BBG0001: "BBG0001",
    BBG0002: "BBG0002",
    BBH0001: "BBH0001",
    BBH0002: "BBH0002",
    BBH0003: "BBH0003",
    BBI0001: "BBI0001",
    BBI0002: "BBI0002",
    BBJ0001: "BBJ0001",
    BBJ0002: "BBJ0002",
    BBJ0003: "BBJ0003",
    BBK0001: "BBK0001",
    BBK0002: "BBK0002",
    BBL0001: "BBL0001",
    BBL0002: "BBL0002",
    BBL0003: "BBL0003",
    BBL0004: "BBL0004",
    BBL0005: "BBL0005",
    INA0001: "INA0001",
    INA0002: "INA0002",
    ACA0001: "ACA0001",
    ACA0002: "ACA0002",
    ACA0003: "ACA0003",
    ACA0004: "ACA0004",
    ACA0005: "ACA0005",
    ACB0001: "ACB0001",
    ACB0002: "ACB0002",
    ACC0001: "ACC0001",
    ACC0002: "ACC0002",
    ACC0003: "ACC0003",
    ACF0001: "ACF0001",
    ACF0002: "ACF0002",
    ACF0003: "ACF0003",
    ACF0004: "ACF0004",
    ACF0005: "ACF0005",
    ACH0001: "ACH0001",
    ACH0002: "ACH0002",
    ACH0003: "ACH0003",
    ACP0001: "ACP0001",
    ACP0002: "ACP0002",
    APA0001: "APA0001",
    APA0002: "APA0002",
    PAA0001: "PAA0001",
    PRI0001: "PRI0001",
    PRI0002: "PRI0002",
    PRI0003: "PRI0003",
    PRI0004: "PRI0004",
    PRI0005: "PRI0005",
    PRI0006: "PRI0006",
    PRI0007: "PRI0007",
    PID0001: "PID0001",
    PID0002: "PID0002",
    PID0003: "PID0003",
    GUR0001: "GUR0001",
    GUR0002: "GUR0002",
    GRP0001: "GPR0001",
    PPG0001: "PPG0001",
    PPG0002: "PPG0002",
    PPG0003: "PPG0003",
    PPG0004: "PPG0004",
    PPG0005: "PPG0005",
    PPG0006: "PPG0006",
    MPR0001: "MPR0001",
    DRN0001: "DRN0001",
    FDR0001: "FDR0001",
    OPL0001: "OPL0001",
    BPL0001: "BPL0001",
    ORD0001: "ORD0001",
    ORD0002: "ORD0002",
    ORC0001: "ORC0001",
    ORC0002: "ORC0002",
    GUP0001: "GUP0001",
    GUP0002: "GUP0002",
    PRT0001: "PRT0001",
    SUP0001: "SUP0001",
    SUP0002: "SUP0002",
    SUP0003: "SUP0003",
    SUP0004: "SUP0004",
    SUP0005: "SUP0005",
    SUP0006: "SUP0006",
    SUP0007: "SUP0007",
    DLU0001: "DLU0001",
    DLU0002: "DLU0002",
    CSD0001: "CSD0001",
    CSD0002: "CSD0002",
    CSD0003: "CSD0003",
    GFG0001: "GFG0001",
    GFG0002: "GFG0002",
    LOC0001: "LOC0001",
    LOC0002: "LOC0002",
    RWO0001: "RWO0001",
    USD0001: "USD0001",
    USD0002: "USD0002",
    GNR0001: "GNR0001",
    GNR0002: "GNR0002",
    GNR0003: "GNR0003",
    RLS0001: "RLS0001",
    GED0001: "GED0001",
    LGI0001: "LGI0001",
    LGF0001: "LGF0001",
    LGF0002: "LGF0002",
    LGF0003: "LGF0003",
    GIO0001: "GIO0001",
    GIO0002: "GIO0002",
    PMU0001: "PMU0001",
    PMU0002: "PMU0002",
    PMU0003: "PMU0003",
    GMU0001: "GMU0001",
    GMU0002: "GMU0002",
    GMU0003: "GMU0003",
    DGC0001: "DGC0001",
    DGC0002: "DGC0002",
    DGC0003: "DGC0003",
    GML0001: "GML0001",
    GFU0001: "GFU0001",
    GFU0002: "GFU0002",
    GFU0003: "GFU0003",
    GFU0004: "GFU0004",
    PCC0001: "PCC0001",
    PCC0002: "PCC0002",
    PCC0003: "PCC0003",
    GCC0001: "GCC0001",
    GCC0002: "GCC0002",
    GCC0003: "GCC0003",
    GCL0001: "GCL0001",
    PUI0001: "PUI0001",
    PUI0002: "PUI0002",
    GPC0001: "GPC0001",
    GPC0002: "GPC0002",
    WSD0001: "WSD0001",
    WSD0002: "WSD0002",
    WSD0003: "WSD0003",
    WSD0004: "WSD0004",
    MWG0001: "MWG0001",
    MWG0002: "MWG0002",
    MWG0003: "MWG0003",
    MWG0004: "MWG0004",
    MWG0005: "MWG0005",
    MWG0006: "MWG0006",
    MWG0007: "MWG0007",
    MWG0008: "MWG0008",
    MWG0009: "MWG0009",
    MWG0010: "MWG0010",
    GRO0001: "GRO0001",
    GRO0002: "GRO0002",
    GRO0003: "GRO0003",
    GRO0004: "GRO0004",
} as const;
export type FunctionCode = typeof ReasonCode[keyof typeof ReasonCode];