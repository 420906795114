
/**
 * 注文依頼サイトにて送信された画像を一定のサイズへリサイズした上で、
 * base64に変更する関数
 * @param imageFile 変換する送信されたファイル
 * @returns base64に変換されたリサイズ後の画像ファイル
 */
export const imageResizeAndConvertBase64 = (imageFile: File): Promise<{ imageBase64: string, imageUrl: string }> => {
    if (imageFile.type !== 'image/jpeg' && imageFile.type !== 'image/png') {
        return Promise.reject(new Error('input invalid file for imageResizer'));
    }

    return new Promise((resolve, reject) => {
        let imageElement = new Image();
        let blodUrl = URL.createObjectURL(imageFile);
        imageElement.src = blodUrl;
        imageElement.onload = async () => {
            let width = imageElement.width;
            let height = imageElement.height;

            let maxWidth = 1200;

            if (width <= maxWidth) {
                try {
                    resolve(makeImageData(imageFile.type, imageElement, width, height));
                } catch (error) {
                    reject(error);
                }
            }

            let newWidth = 1200;
            let newHeight = height * (newWidth / width);
            try {
                resolve(makeImageData(imageFile.type, imageElement, newWidth, newHeight));
            } catch (error) {
                reject(error);
            }
        };
        imageElement.onerror = (event) => {
            console.log(event);
            return reject(new Error('error occered in imageElement at imageResizer'));
        }
    })
}

const makeImageData = (
    imageFileType: string,
    imageElement: HTMLImageElement,
    width: number,
    height: number): { imageBase64: string, imageUrl: string } => {
    let canvas = document.createElement('canvas');

    canvas.width = width;
    canvas.height = height;

    let context = canvas.getContext('2d');

    if (context) {
        context.drawImage(imageElement, 0, 0, width, height);

        let imageBase64 = canvas.toDataURL(imageFileType);
        let imageUrl = URL.createObjectURL(toBlob(imageBase64)!);

        return { imageBase64, imageUrl };
    } else {
        throw new Error('can\'t get context at imageResizer');
    }
}

//引数はbase64形式の文字列
function toBlob(base64: string): Blob | undefined {
    if (/^data:\w+\/\w+;base64,/.test(base64)) {
        let bin = atob(base64.replace(/^data:\w+\/\w+;base64,/, ''));

        const contentType = base64.toString().slice(
            base64.indexOf(':') + 1, base64.indexOf(';')
        );

        let buffer = new Uint8Array(bin.length);
        for (let i = 0; i < bin.length; i++) {
            buffer[i] = bin.charCodeAt(i);
        }
        // Blobを作成
        let blob = new Blob([buffer.buffer], {
            type: contentType
        });
        return blob;
    } else {
        return undefined;
    }
}