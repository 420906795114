/**
 * 位置情報データを表すデータ型
 */
export interface GeoInformation {
    //フライトID
    flightId: string,
    //時間
    sendTime: string
    //ドローンID
    droneId: string,
    //緯度
    latitude: number,
    //経度
    longitude: number,
    //高度
    altitude: number,
}

/**
 * MAPスタイル
 */
export const MapStyle = {
    /**
     * 地形
     */
    neutral: "neutral",
    /**
     * 衛星写真
     */
    satellite: "satellite"
} as const;
/**
 * ポートタイプ
 */
export type MapStyle = typeof MapStyle[keyof typeof MapStyle];