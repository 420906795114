import { makeStyles } from 'tss-react/mui';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { OrderInfo } from 'adoms-common-lib';
import React from 'react';


const useStyles = makeStyles()((theme) => ({
    paper: {
        padding: '12px',
        textAlign: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
    },
}));


/**
 * 受け取り人情報を表示する
 * @param props order
 */
export default function OrderDestinationInfo(props: any) {
    const { classes } = useStyles();
    const order = props.order as OrderInfo;
    return (
        <div>
            <Paper className={classes.paper} elevation={0}>
                <Typography variant="h5" component="div">
                    {order.destination.lastname} {order.destination.firstname}様
                </Typography>
                <Typography variant="body1" component="div">
                    〒{order.destination.zipcode}
                </Typography>
                <Typography variant="body1" component="div">
                    住所：{order.destination.prefecture}{order.destination.address}
                </Typography>
                <Typography variant="body1" component="div">
                    電話番号：{order.destination.tel !== "" ? order.destination.tel : "***"}
                </Typography>
            </Paper>
        </div>
    );

}