import { Alert, Dialog, DialogContent, Grid } from "@mui/material";
import { OrderInfo } from "adoms-common-lib";
import { QRCodeCanvas } from "qrcode.react";
import React from 'react';

type QRCodeDigalogProps = {
    order?: OrderInfo
    isDisplayQRCode: boolean
    setDisplayQRCode: React.Dispatch<React.SetStateAction<boolean>>
};

export const QRCodeDigalog: React.FC<QRCodeDigalogProps> = (props) => {
    return (
        <Dialog
            open={props.isDisplayQRCode}
            onClose={(e) => props.setDisplayQRCode(false)}>
            <DialogContent sx={{ textAlign: "center" }}>
                {props.order?.orderID ?
                    <React.Fragment>
                        <Grid item xs={12} sm={12} >
                            <QRCodeCanvas value={props.order?.orderID} />
                        </Grid>
                        <Grid item xs={10} sm={10} >
                            <Alert severity="warning" style={{ textAlign: "left" }}>
                                お問合せの際には確認番号もしくはQRコードをお伝えください。
                            </Alert>
                        </Grid>
                    </React.Fragment>
                    : undefined}
            </DialogContent>
        </Dialog>
    )
}