import React, { useEffect, useState } from "react";
import { Navigate, Outlet, Route, Routes, useLocation, useParams, useRouteLoaderData } from "react-router-dom";
import { CustomerURLInformationUtil } from "./customerURLInformationUtil";

const PrivateRoute = () => {
    const [isValidCustomerURLInformation, setIsValidCustomerURLInformation] = useState<boolean | null>(null);
    const location = useLocation();
    const params = useParams<{ urlId: string }>()
    useEffect(() => {
        /**
        * CustomerURLInformationUtilからCustomerURLInformationを取得する
        * @param urlId URLID
        */
        const customerURLInformation = async (urlId: string) => {
            let accessToTopPageRegex = /^\/(orderflightsearchinput\/)?[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/;
            let customerURLInformationUtil = CustomerURLInformationUtil.instance;
            return await customerURLInformationUtil.updateCustomerURLInformationByUrlId(urlId).then((response) => {
                if (
                    accessToTopPageRegex.test(location.pathname) || 
                    response !== undefined
                ) {
                    setIsValidCustomerURLInformation(true);
                } else {
                    setIsValidCustomerURLInformation(false);
                }
            }).catch((error) => {
                console.log(error);
                setIsValidCustomerURLInformation(false);
            });
        };
        if (params.urlId !== undefined) {
            customerURLInformation(params.urlId);
        } else {
            console.log("not call customerURLInformation");
            setIsValidCustomerURLInformation(false);
        }
    }, []);

    if (isValidCustomerURLInformation !== null && isValidCustomerURLInformation) {
        return <Outlet />
    } else if (isValidCustomerURLInformation !== null && !isValidCustomerURLInformation) {
        return <Navigate to="/forbidden403Page" />
    } else {
        return (
            <div></div>
        )
    }
}

export default PrivateRoute;