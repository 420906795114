import { CertifiedUserInfo, PartnerKind, UserInfo } from "../UserInfomation";
import { DroneInfo, DroneStatus } from "./DroneInfo";
import { FlightStatus } from "./FlightStatus";
import { Port, createEmptyPort } from "./Port";


/**
 * フライト情報
 */
export interface FlightInfo {
    /**
     * フライトID
     */
    id: string,
    /**
     * フライトナンバー
     */
    num: string,
    /**
     * フライトステータス
     */
    status: FlightStatus,
    /**
     * フライト日
     */
    flightDate: string,
    /**
    * ビジネスパートナーID
    */
    businessPartnerId: string,
    /**
     * 出発地
     */
    departure: Port,
    /**
     * 到着地
     */
    arrival: Port,

    std: string,
    sta: string,
    etd: string,
    eta: string,
    atd: string,
    ata: string,

    /**
     * 使用機材情報
     */
    drone: DroneInfo,
    /**
     * フライトディレクター情報
     */
    fd: UserInfo | CertifiedUserInfo
    /**
     * 備考
     */
    remarks: string,
    /**
     * イレギュラーが起きたときの情報を引っ張ってくるID
     */
    irregularId?: string
    /**
     * 将来的に消す！
     */
    payload?: number,
    /**
     * 飛行目的 
     */
    purpose: Purpose,
    /**
     * 特定飛行 
     */
    specificList?: Specific[],
    /**
     * 飛行の安全に影響のあった事項 
     */
    unsafeEvent?: string,
    /**
     * 記事
     */
    report?: Report
};
/**
 * フライトルート
 */
export interface FlightRoute {
    /**
     * 予定ルート
     */
    scheduledRoute: RouteInfo,
    /**
     * 現状の推定ルート
     */
    estimatedRoute: RouteInfo,
    /**
     * 実際のルート（実績。フライト後に入力される）
     */
    actualRoute?: RouteInfo,
}

/**
 * ルート情報
 */
export interface RouteInfo {
    /** 
     * 出発ポート 
     */
    depurturePort: Port,
    /**
     * 到着ポート
     */
    arrivalPort: Port,
    /**
     * 出発時間
     */
    depurtureTime: string,
    /**
     * 到着時間
     */
    arrivalTime: string,
}

/**
 * 飛行目的
 */
export const Purpose = {
    Transport: "輸送",
    EnviromentalSurvey: "環境調査",
    TestFlight: "テストフライト"
} as const;
export type Purpose = typeof Purpose[keyof typeof Purpose];

/**
 * 特定飛行
 */
export const Specific = {
    Airport: "空港等周辺",
    Over150m: "地表又は水面から150m以上",
    DID: "人口集中地区（DID）上空",
    Night: "夜間",
    OutOfSight: "目視外",
    Within30M: "人又は物件から 30m 未満",
    Event: "催し場所上空",
    Dangerous: "危険物輸送",
    Drop: "物件投下の飛行",
} as const;
export type Specific = typeof Specific[keyof typeof Specific];

/**
 * 記事
 */
export interface Report {
    /**
     * 発生年月日
     */
    squawkDate: string,
    /**
     * 不具合事項
     */
    flightSquawk: string,
    /**
     * 処置年月日
     */
    actionDate?: string,
    /**
     * 処置その他
     */
    correctiveAction?: string,
    /**
     * 確認者
     */
    confirmer?: string
};

/**
 * テスト用のデータ
 */
export function createDefault(): FlightInfo {
    const fi: FlightInfo = {
        id: "",
        num: "",
        status: FlightStatus.Empty,
        flightDate: "",
        businessPartnerId: "",
        std: "",
        sta: "",
        etd: "",
        eta: "",
        atd: "",
        ata: "",
        fd: {
            sub: "",
            tenantId: "",
            email: "",
            groupInfoList: [],
            privilege: {
                dataPrivilegeInfoList: [],
                functionPrivilegeInfoList: [],
            },
            name: "",
            userPartnerInfo: {
                partnerKind: PartnerKind.Operation,
                partnerInfo: {
                    operationPartnerId: "",
                    operationPartnerName: "",
                    tenantId: "",
                    sortNumber: 0,
                    visibleBusinessPartnerInfoList: []
                }
            }
        },
        departure: createEmptyPort(),
        arrival: createEmptyPort(),
        drone: {
            id: "ana001",
            tenantId: "tenantId",
            sort: 1,
            img1: "/img/drone/default.png",
            payload: 1500,
            label: "ana001(テストデータ)",
            maxFlightMinutes: 60,
            preparationMinutes: 30,
            kind: "testdata",
            remarks: "",
            status: DroneStatus.Accepting,
            businessPartnerInfoList: [],
            registrationId: "",
            serialNumber: "",
            designManufacturer: ""
        },
        remarks: "",
        purpose: Purpose.Transport,
    }
    return fi;
}