import React from 'react';
import { createTheme, ThemeProvider, StyledEngineProvider, adaptV4Theme } from '@mui/material/styles';
import { orange, blue } from '@mui/material/colors';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material/styles';

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
        textAlign: 'center'
    }
}));


type ApplicationTooBarProps = {
    label: string,
    bgcolor?: string,
}

const ApplicationTooBar: React.FC<ApplicationTooBarProps> = (props) => {
    const { classes } = useStyles();

    return (
        (props.bgcolor === undefined) ? (
            <AppBar position="static">
                <Toolbar>
                    <Typography variant="h6" className={classes.title}>
                        {props.label} 
                    </Typography>
                </Toolbar>
            </AppBar>
        ) : (
            <AppBar position="static" style={{ backgroundColor: props.bgcolor }}>
                <Toolbar>
                    <Typography variant="h6" className={classes.title}>
                        {props.label} 
                    </Typography>
                </Toolbar>
            </AppBar>
        )
    );
}

export default ApplicationTooBar;