import { MapView } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import PlaceIcon from '@mui/icons-material/Place';
import StoreIcon from '@mui/icons-material/Store';
import { Grid, Alert, Button, Paper, SvgIcon, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { blue } from '@mui/material/colors';
import { Display, GeoInformation, MapStyle, OrderInfo, Port, ReceiveStatus } from 'adoms-common-lib';
import { Auth, Geo } from 'aws-amplify';
import * as geolib from "geolib";
import React, { useEffect, useMemo } from 'react';
import { Layer, Marker, Source, NavigationControl, Popup, LineLayer } from 'react-map-gl';
import { ReactComponent as DroneIcon } from "../../asset/icons/quadcopter.svg";
import { APIConnector } from '../../connector/APIConnector';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { load } from "@loaders.gl/core";
import { KMLLoader } from '@loaders.gl/kml';
import type { FeatureCollection } from 'geojson';

type AmplifyGeoConfig = {
    geo?: {
        amazon_location_service?: AmplifyGeoOptions;
        AmazonLocationService?: AmplifyGeoOptions;
    };
};

type AmplifyGeoOptions = {
    maps?: {
        neutral: string,
        satellite: string
    };
    region: string;
};

type DroneMapViewProps = {
    order: OrderInfo | undefined
    tenantId: string | undefined
}

type GeoInformationPerFlightId = {
    flightId: string,
    geoInformationList: GeoInformation[]
};

/**
 * ドローン位置情報マップを表示する
 * @param props order
 */
export const DroneMapView: React.FC<DroneMapViewProps> = (props) => {

    const amplifyConfig = Geo.configure() as AmplifyGeoConfig;
    Auth.configure();

    const geoConfig = useMemo(
        () =>
            amplifyConfig.geo?.amazon_location_service ??
            amplifyConfig.geo?.AmazonLocationService ??
            ({} as AmplifyGeoOptions),
        [amplifyConfig]
    );
    const styleProps = useMemo(
        () => ({
            height: "350px",
            position: 'relative',
            width: '100%'
        }),
        []
    );
    type MinMaxLanLng = {
        minLat: number,
        maxLat: number,
        minLng: number,
        maxLng: number
    };
    const [mapErrorMessage, setMapErrorMessage] = React.useState<string | undefined>();
    const [geoConfigMapStyle, setGeoConfigMapStyle] = React.useState<string | undefined>(geoConfig.maps?.neutral);
    const [geoInformationByOrderIdErrorMessage, setGeoInformationByOrderIdErrorMessage] = React.useState<string | undefined>();
    const [geoInformationListPerFlightIdList, setGeoInformationListPerFlightIdList] = React.useState<GeoInformationPerFlightId[]>([]);
    const [mapStyle, setMapStyle] = React.useState<MapStyle>(MapStyle.neutral);
    const [departurePortPopupInfo, setDeparturePortPopupInfo] = React.useState<Port | undefined>();
    const [arrivalPortPopupInfo, setArrivalPortPopupInfo] = React.useState<Port | undefined>();
    const [dronePopupInfo, setDronePopupInfo] = React.useState<GeoInformation | undefined>();
    const [minMaxLanLng, setMinMaxLanLng] = React.useState<MinMaxLanLng | undefined>(undefined);
    const [routeData, setRouteData] = React.useState<FeatureCollection | undefined>();

    /**
     * 画面表示時に地図の中心となる点を算出する
     * また、ドローンの位置情報を取得する
     */
    useEffect(() => {
        if (props.order) {
            const minMaxLanLngParam = geolib.getBounds([
                {
                    longitude: Number(props.order.departure.longitude),
                    latitude: Number(props.order.departure.latitude)
                },
                {
                    longitude: Number(props.order.arrival.longitude),
                    latitude: Number(props.order.arrival.latitude)
                },
            ]);
            if (!minMaxLanLngParam) {
                // 出発地点と到着地点の中間地点の座標取得に失敗した場合
                setMapErrorMessage("地図表示地点を取得できませんでした");
            } else {
                setMapErrorMessage(undefined);
                setMinMaxLanLng(minMaxLanLngParam);
            };
            const dataload = async () => {
                // 表示させるルートを取得する
                const kmlFilePath = "./kml/orderAndRequesterView/" + props.order?.businessPartnerId
                    + "_" + props.order?.departure.id
                    + "_" + props.order?.arrival.id + ".kml";
                //KMLデータを読み込む
                const res = await load(kmlFilePath, KMLLoader);
                //KMLローダーで読み込んだデータはGeoJSON形式に変換されている
                console.log(res);
                //読み込んだデータを保存
                setRouteData(res);
            };
            dataload();
            fetchGeoInformationByFlightId();
        };
    }, [props.order]);

    /**
     * 配送が完了、またはキャンセルされていない場合、
     * 5秒間隔でドローンの位置情報を取得する
     */
    useEffect(() => {
        if (props.order?.receiveStatus !== ReceiveStatus.Received
            && props.order?.receiveStatus !== ReceiveStatus.Cancel) {
            let timeoutId: number | undefined
            timeoutId = window.setTimeout(() => fetchGeoInformationByFlightId(), 5 * 1000);
            return () => {
                if (typeof timeoutId !== "undefined") {
                    window.clearTimeout(timeoutId);
                };
            };
        }
    }, [geoInformationListPerFlightIdList]);

    /**
     * フライトIDごとのドローンの位置情報を取得する
     */
    const fetchGeoInformationByFlightId = async () => {
        if (document.hasFocus()) {
            // 画面がアクティブの場合
            let c: APIConnector = APIConnector.instance;
            if (props.order && props.tenantId) {
                await c.getGeoInformationByOrderId(props.order.orderID, props.tenantId).then((geoInformationPerFlightIdMap: Map<string, GeoInformation[]>) => {
                    console.log(geoInformationPerFlightIdMap);
                    setGeoInformationByOrderIdErrorMessage(undefined);
                    let geoInformationListPerFlightIdList: GeoInformationPerFlightId[] = new Array();
                    geoInformationPerFlightIdMap.forEach(function (geoInformation, flightId) {
                        if (geoInformation) {
                            let geoInformationListPerFlightId = {
                                flightId: flightId,
                                geoInformationList: geoInformation
                            };
                            geoInformationListPerFlightIdList.push(geoInformationListPerFlightId);
                        };
                    });
                    setGeoInformationListPerFlightIdList(geoInformationListPerFlightIdList);
                }).catch((error) => {
                    console.log(error);
                    setGeoInformationByOrderIdErrorMessage("ドローン位置情報を取得できませんでした。");
                });
            };
        } else {
            setGeoInformationListPerFlightIdList([...geoInformationListPerFlightIdList]);
        }
    };

    /**
     * ドローンの経路（マーカー）の色をグラデーションで表示する。
     * 先頭のマーカーはドローンのアイコンを表示させる。
     * @param locationIndex 
     */
    const handleDisplayLocationIcon = (locationIndex: number) => {

        if (locationIndex === 0) {
            return (
                <SvgIcon
                    sx={{
                        color: mapStyle === MapStyle.satellite ? "#ffffff" : "#000000",
                        stroke: mapStyle === MapStyle.satellite ? "#000000" : "#ffffff",
                        strokeWidth: "0.5px"
                    }}>
                    <DroneIcon />
                </SvgIcon >
            );
        } else {
            return (
                <FiberManualRecordIcon
                    sx={{ color: "#e06666", fontSize: 10 }} />
            );
        };
    };

    /**
     * ドローン用ポップアップを表示させる
     */
    const displayDronePopupInfo = () => {
        if (dronePopupInfo) {
            let sendTime = Display.getTimeMiniteSecondStringFromISO8601(dronePopupInfo.sendTime);
            return (
                <Popup
                    longitude={dronePopupInfo.longitude}
                    latitude={dronePopupInfo.latitude}
                    anchor="bottom"
                    onClose={() => setDronePopupInfo(undefined)}
                    style={{ zIndex: 100 }}>
                    <Grid container direction="row">
                        <ScheduleIcon fontSize="small" sx={{ marginRight: "2px" }} />
                        <Typography variant='subtitle2'>{sendTime}</Typography>
                    </Grid>
                </Popup>
            );
        };
    };

    /**
     * ポート用ポップアップを表示させる
     */
    const displayPortPopupInfo = () => {

        if (departurePortPopupInfo) {
            return (
                <Popup
                    longitude={Number(departurePortPopupInfo.longitude)}
                    latitude={Number(departurePortPopupInfo.latitude)}
                    anchor="bottom"
                    onClose={() => setDeparturePortPopupInfo(undefined)}>
                    <Typography variant='subtitle2' sx={{ color: "#2196f3" }}>{"出発地"}</Typography>
                    <Typography variant='subtitle1' fontWeight={"bold"} sx={{ marginBottom: "3px" }}>
                        {departurePortPopupInfo.name}
                    </Typography>
                    <Typography variant='subtitle2'>
                        〒{departurePortPopupInfo.zipcode}
                    </Typography>
                    <Typography variant='subtitle2'>
                        {departurePortPopupInfo.prefecture}{departurePortPopupInfo.address}
                    </Typography>
                </Popup>
            );
        } else if (arrivalPortPopupInfo) {
            return (
                <Popup
                    longitude={Number(arrivalPortPopupInfo.longitude)}
                    latitude={Number(arrivalPortPopupInfo.latitude)}
                    anchor="bottom"
                    onClose={() => setArrivalPortPopupInfo(undefined)}>
                    <Typography variant='subtitle2' sx={{ color: "#2196f3" }}>{"到着地"}</Typography>
                    <Typography variant='subtitle1' fontWeight={"bold"} sx={{ marginBottom: "3px" }}>
                        {arrivalPortPopupInfo.name}
                    </Typography>
                    <Typography variant='subtitle2'>
                        〒{arrivalPortPopupInfo.zipcode}
                    </Typography>
                    <Typography variant='subtitle2'>
                        {arrivalPortPopupInfo.prefecture}{arrivalPortPopupInfo.address}
                    </Typography>
                </Popup>
            );
        };
    };

    /**
     * 地図の表示形式が変更された時のハンドラ
     * @param mapStyle 
     */
    const handleChangeMapStyle = (mapStyle: MapStyle) => {
        setMapStyle(mapStyle);
        if (mapStyle === MapStyle.neutral) {
            setGeoConfigMapStyle(geoConfig.maps?.neutral);
        } else if (mapStyle === MapStyle.satellite) {
            setGeoConfigMapStyle(geoConfig.maps?.satellite);
        };
    };

    const lineLayerStyle: LineLayer = {
        id: 'line',
        type: 'line',
        paint: {
            "line-color": "#ea9245",
            "line-width": 3
        }
    };

    return (
        <React.Fragment>
            {
                mapErrorMessage ?
                    <Paper sx={{
                        padding: "10px",
                        marginBottom: "10px"
                    }} elevation={1} >
                        <Alert severity="error">{mapErrorMessage}</Alert>
                    </Paper>
                    : undefined
            }
            {
                geoInformationByOrderIdErrorMessage ?
                    <Paper sx={{
                        padding: "10px",
                        margin: "10px"
                    }} elevation={1} >
                        <Alert severity="error">{geoInformationByOrderIdErrorMessage}</Alert>
                    </Paper>
                    : undefined
            }
            {
                props.order && minMaxLanLng ?
                    <MapView
                        initialViewState={
                            props.order.departure.id
                                === props.order.arrival.id ?
                                {
                                    latitude: Number(props.order.departure.latitude),
                                    longitude: Number(props.order.departure.longitude),
                                    zoom: props.order.businessPartnerId === "AIRWINGS" ? 13 : 15
                                }
                                : {
                                    bounds:
                                        [
                                            [minMaxLanLng.minLng, minMaxLanLng.minLat],
                                            [minMaxLanLng.maxLng, minMaxLanLng.maxLat]],
                                    fitBoundsOptions: {
                                        padding: 60
                                    }
                                }}
                        style={styleProps as React.CSSProperties}
                        onError={error => {
                            console.log(error);
                            if (error) {

                            }
                            setMapErrorMessage("地図情報を取得できませんでした。ページを更新してください。");
                        }}
                        mapStyle={geoConfigMapStyle}>
                        <Marker
                            longitude={Number(props.order.departure.longitude)}
                            latitude={Number(props.order.departure.latitude)}
                            onClick={e => {
                                e.originalEvent.stopPropagation();
                                setDeparturePortPopupInfo(props.order?.departure);
                            }}>
                            <Avatar sx={{ height: 30, width: 30, backgroundColor: blue[500] }}>
                                <StoreIcon fontSize='small' />
                            </Avatar>
                        </Marker>
                        <Marker
                            longitude={Number(props.order.arrival.longitude)}
                            latitude={Number(props.order.arrival.latitude)}
                            onClick={e => {
                                e.originalEvent.stopPropagation();
                                setArrivalPortPopupInfo(props.order?.arrival);
                            }}>
                            <PlaceIcon fontSize='large' sx={{ color: "#dc143c" }} />
                        </Marker>
                        {geoInformationListPerFlightIdList.map((geoInformationListPerDroneIdId, droneIndex) => (
                            (geoInformationListPerDroneIdId.geoInformationList).map((geoInformation, locationIndex) => (
                                <Marker
                                    key={`marker-${droneIndex}-${locationIndex}`}
                                    longitude={geoInformation.longitude}
                                    latitude={geoInformation.latitude}
                                    style={{ zIndex: geoInformationListPerDroneIdId.geoInformationList.length }}
                                    onClick={e => {
                                        e.originalEvent.stopPropagation();
                                        setDronePopupInfo(geoInformation)
                                    }}
                                >
                                    {handleDisplayLocationIcon(locationIndex)}
                                </Marker>
                            ))
                        ))}
                        {displayPortPopupInfo()}
                        {displayDronePopupInfo()}
                        {mapStyle === MapStyle.satellite ?
                            <Button
                                sx={{ margin: "10px", minWidth: "0px" }}
                                size='small'
                                variant="contained"
                                onClick={(e) => handleChangeMapStyle(MapStyle.neutral)}
                            >
                                地形
                            </Button>
                            :
                            <Button
                                sx={{ margin: "10px", minWidth: "0px" }}
                                size='small'
                                variant="contained"
                                onClick={(e) => handleChangeMapStyle(MapStyle.satellite)}
                            >
                                衛星
                            </Button>
                        }
                        <NavigationControl style={{ margin: "10px" }} />
                        {routeData ?
                            <Source id="route" type="geojson" data={routeData}>
                                <Layer {...lineLayerStyle} />
                            </Source>
                            : undefined}
                    </MapView>
                    : undefined
            }
        </React.Fragment >
    )
}