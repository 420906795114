import { DataPrivilegeInfo, FunctionPrivilegeInfo, GroupInfo } from "./PrivilegeInfomation";
import { PushNotificationArns } from "./PushNotificationArns";


/**
 * ADOMS管理者の名称
 */
export const ADOMS_ADOMINISTRATOR_NAME: string = "ADOMS管理者";

/**
 * ユーザー情報
 */
export interface UserInfo {
    /**
     * Cognitoのsub
     */
    sub: string,
    /**
     * テナントID
     */
    tenantId: string,
    /**
     * ユーザー名
     */
    name: string,
    /**
    * メールアドレス
    */
    email: string,
    /**
     * ユーザーパートナー情報
     */
    userPartnerInfo: UserPartnerInfo,
    /**
     * グループリスト
     */
    groupInfoList: GroupInfo[],
    /**
     * 権限
     */
    privilege: {
        functionPrivilegeInfoList: FunctionPrivilegeInfo[],
        dataPrivilegeInfoList: DataPrivilegeInfo[]
    },
    /**
     * 各ユーザーから登録されたプッシュ通知のARNのリスト
     */
    pushNotificationArns?: PushNotificationArns[]
}

/**
 * 有資格者ユーザー情報
 */
export interface CertifiedUserInfo extends UserInfo {
    /**
     * 技能証明書番号
     */
    competenceCertificateNumber: string
}

/**
 * CertifiedUserInfoかどうかを判定するタイプガード
 */
export const isCertifiedUserInfo = (arg: any): arg is CertifiedUserInfo => {
    return (
        arg !== null &&
        typeof arg === "object" &&
        typeof arg.competenceCertificateNumber === "string"
    );
};

/**
 * ユーザーパートナー情報
 */
export interface UserPartnerInfo {
    /**
     * パートナー種別
     */
    partnerKind: PartnerKind,
    /**
     * パートナー情報
     */
    partnerInfo: OperationPartnerInfo | BusinessPartnerInfo
}

/**
 * オペレーションパートナー情報
 */
export interface OperationPartnerInfo {
    /**
     * オペレーションパートナーID
     */
    operationPartnerId: string,
    /**
     * オペレーションパートナー名
     */
    operationPartnerName: string,
    /**
     * テナントID
     */
    tenantId: string,
    /**
     * 閲覧可能なビジネスパートナーID
     */
    visibleBusinessPartnerInfoList: BusinessPartnerInfo[],
    /**
     * ソート番号
     */
    sortNumber: number,
    /**
     * 備考
     */
    remarks?: string,
}

/**
 * ビジネスパートナー情報
 */
export interface BusinessPartnerInfo {
    /**
     * ビジネスパートナーID
     */
    businessPartnerId: string,
    /**
     * ビジネスパートナー名
     */
    businessPartnerName: string,
    /**
     * テナントID
     */
    tenantId: string,
    /**
     * ソート番号
     */
    sortNumber: number,
    /**
     * 備考
     */
    remarks?: string,
}

/**
 * パートナー種別
 */
export const PartnerKind = {
    Business: "Business",
    Operation: "Operation"
} as const;
export type PartnerKind = typeof PartnerKind[keyof typeof PartnerKind];

/**
 * オペレーションパートナーIDの初期化データ
 */
export interface InitOperationPartnerInfo { 
    tenantId: string, 
    data: OperationPartnerInfo[] 
}

/**
 * ビジネスパートナーIDの初期化データ
 */
export interface InitBusinessPartnerInfo { 
    tenantId: string, 
    data: BusinessPartnerInfo[] 
}

export interface DeleteOperationPartnerInfo { 
    tenantId: string, 
    Key: {
        operationPartnerId: string,
    }[] 
}

export interface DeleteBusinessPartnerInfo { 
    tenantId: string, 
    Key: {
        businessPartnerId: string
    }[] 
}