import { FormControl, Paper } from '@mui/material';
import { blue } from '@mui/material/colors';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import HomeIcon from '@mui/icons-material/Home';
import Alert from '@mui/material/Alert';
import { OrderInfo } from 'adoms-common-lib';
import { QRCodeCanvas } from 'qrcode.react';
import React, { useEffect } from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import ApplicationTooBar from '../../components/molecule/ApplicationToolBar';
import OrderDestinationInfo from '../../components/molecule/OrderDestinationInfo';
import OrderGoodsTable from '../../components/molecule/OrderGoodsTable';
import OrderStepBar, { Process } from '../../components/molecule/OrderStepBar';
import { CustomerURLInformationUtil } from '../../common/customerURLInformationUtil';

const useStyles = makeStyles()((theme) => ({
    griditem: {
    },

    table: {
        minWidth: 100,
    },
    paper: {
        padding: '12px',
        textAlign: 'center',
        height: '100%',
    },
    confirmNum: {
        textAlign: 'center',
        height: '100%',
        width: '300px'
    },
    title: {
        flexGrow: 1,
        textAlign: 'center',
        backgroundColor: blue[500],
        color: 'white',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 32,
    },
    link: {
        textDecoration: 'none',
    }
}));




function createData(name: string, gram: number, count: number) {
    return { name, gram, count };
}


export default function OrderComplete() {
    const { classes } = useStyles();
    const navigate = useNavigate();
    const location = useLocation();
    const order = location.state.order as OrderInfo;
    //URLIDコピーのためシャローコピー
    const customerURLInformation = {...CustomerURLInformationUtil.instance.getCustomerURLInformation()};

    useEffect(() => {
        if (customerURLInformation !== undefined) {
            CustomerURLInformationUtil.instance.deleteCustomerURLInformation();
        }
        console.log(`/${customerURLInformation.urlId}`);
    });

    const handleNavigateTopPage = async () => {
        if (customerURLInformation.urlId != null) {
            await CustomerURLInformationUtil.instance.updateCustomerURLInformationByUrlId(customerURLInformation.urlId);
            navigate(`/${customerURLInformation.urlId}`);
        } else {
            console.log("undefined urlId");
        }
    }

    return (
        <div>
            <Grid container justifyContent="center" xs={12} spacing={1}>
                <Grid item xs={12} sm={12} >
                    <ApplicationTooBar label="配送依頼完了" />
                </Grid>
                <Grid item xs={12} sm={12} className={classes.griditem}>
                    <OrderStepBar process={Process.Complete} />
                    <Alert severity="success">以下の内容で配送依頼が完了しました。　確認番号をお控えのうえ配送物をドローンオペレーターにお渡しください。  </Alert>
                </Grid>
                <Grid item xs={12} sm={12} className={classes.griditem}>
                    <div>
                        <Typography className={classes.title} variant="h6" component="div">
                            確認番号
                        </Typography>
                        <Paper className={classes.paper} elevation={0}>
                            <Grid container justifyContent="center">
                                <Grid item xs={12} sm={12} className={classes.griditem}>
                                    <Typography variant="h5" component="div" color="secondary">
                                        {order.orderID}
                                        <div><QRCodeCanvas value={order.orderID} /></div>
                                    </Typography>
                                </Grid>
                                <Grid item xs={2} sm={2} className={classes.griditem}>
                                </Grid>
                                <Grid item xs={8} sm={8} className={classes.griditem}>
                                    <Alert severity="warning" style={{ textAlign: "center" }}>確認番号は配送時の受け取りに必要な番号です。QRコードを保存するか、必ずメモをお取りください</Alert>
                                </Grid>
                                <Grid item xs={2} sm={2} className={classes.griditem}>
                                </Grid>

                            </Grid>

                        </Paper>
                    </div>
                </Grid>
                {/* <Typography className={classes.title} variant="h6" component="div">
                外部注文情報
                </Typography>
                <Grid item xs={12} sm={12} className={classes.griditem}>
                    <Paper className={classes.paper} elevation={0}>
                        外部注文番号
                        <Typography variant="h5" component="div" color="secondary">
                            {order.externalOrderID !== undefined ? order.externalOrderID : "連携なし"}
                        </Typography>
                    </Paper>
                </Grid> */}
                <Typography className={classes.title} variant="h6" component="div">
                    配送希望時間
                </Typography>
                <Grid item xs={12} sm={12} className={classes.griditem}>
                    <Paper className={classes.paper} elevation={0}>
                        <Typography variant="h5" component="div" color="secondary">
                            {
                                order.preferredDeliveryDate !== '' &&
                                    order.preferredDeliveryTime !== '' ?
                                    `${order.preferredDeliveryDate} ${order.preferredDeliveryTime}` :
                                    "指定なし"
                            }
                        </Typography>
                    </Paper>
                </Grid>
                <Typography className={classes.title} variant="h6" component="div">
                    荷物の受取人
                </Typography>
                <Grid item xs={12} sm={12} className={classes.griditem}>
                    <OrderDestinationInfo order={order} />
                </Grid>

                <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                    店舗担当者連絡先
                </Typography>
                <Grid item xs={12} sm={12} className={classes.griditem}>
                    <Paper className={classes.paper} elevation={0}>
                        <Typography variant="body1" component="div">
                            電話番号：{order.destination.tel2}
                        </Typography>
                    </Paper>
                </Grid>
                <Typography className={classes.title} variant="h6" component="div">
                    発着ポート
                </Typography>
                <Grid item xs={12} sm={12} className={classes.griditem}>
                    <Paper className={classes.paper} elevation={0}>
                        {order.departure.name} ⇒ {order.arrival.name}
                    </Paper>
                </Grid>

                <Typography className={classes.title} variant="h6" component="div">
                    配送物
                </Typography>
                <Grid item xs={12} sm={12} className={classes.griditem}>
                    <OrderGoodsTable order={order} />
                </Grid>

                <Grid item xs={12} sm={12} className={classes.griditem}>
                    <Paper className={classes.paper} elevation={0}>
                        <FormControl variant="standard" className={classes.formControl}>
                            <IconButton 
                                edge="start" 
                                color="primary" 
                                aria-label="menu" 
                                size="large"
                                onClick={() => handleNavigateTopPage()}
                            >
                                <HomeIcon /><Typography>ホームに戻る</Typography>
                            </IconButton>
                        </FormControl>
                    </Paper>
                </Grid>

            </Grid>
        </div >
    );
}