/**
 * 
 */
export interface GoodsInfo {
    weight: number,
    name: string,
    count: number,
}



export function getTotalWeight(items: GoodsInfo[]): number {
    let result = 0;
    items.map((v, i) => result += (v.weight * v.count));
    return result;
}