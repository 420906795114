import React from 'react';
import { makeStyles } from 'tss-react/mui';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Divider } from '@mui/material';

const useStyles = makeStyles()((theme) => ({
    root: {
      width: '100%',
    },
    button: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    }
  }));


export enum Process {
  FlightSelect = 0,
  DestinationInput = 1,
  Confirm = 2,
  Complete = 3,
}


function getSteps() {
  return ['配送区間指定', '受け取り情報入力', '確認', '完了'];
}

export default function OrderStepBar(props: any) {
  const { classes } = useStyles();
  const steps = getSteps();
  const process: Process = props.process;

  return (
    <div className={classes.root}>
      <Stepper activeStep={process} alternativeLabel  style={{ padding: 24 }}>
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: { optional?: React.ReactNode } = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <Divider />
    </div>
  );
}