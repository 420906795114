import DoneIcon from '@mui/icons-material/Done';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import { Avatar, Step, StepIconProps, StepLabel, Stepper, SvgIcon } from '@mui/material';
import { styled } from '@mui/material/styles';
import { OrderInfo, ReceiveStatus } from "adoms-common-lib";
import React from 'react';
import { ReactComponent as DroneIcon } from "../../asset/icons/quadcopter.svg";
import { ReactComponent as PackageIcon } from "../../asset/icons/package-variant-closed.svg";
import { ReactComponent as StoreClockIcon } from "../../asset/icons/store-clock.svg";
import Store from '@mui/icons-material/Store';

type ReceiveStatusStepperProps = {
    order?: OrderInfo
};

const ActiveStepAvater = styled(Avatar)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    width: 30,
    height: 30
}));

const UnfinishedStepAvater = styled(Avatar)(({ theme }) => ({
    backgroundColor: theme.palette.grey[500],
    width: 30,
    height: 30
}));

/**
 * 配送ステータス表示ステッパー
 * @param props order
 */
export const ReceiveStatusStepper: React.FC<ReceiveStatusStepperProps> = (props) => {
    const steps: ReceiveStatus[] = [
        ReceiveStatus.WaitingForPickup,
        ReceiveStatus.Preparation,
        ReceiveStatus.Loaded,
        ReceiveStatus.Received,
    ];

    /**
     * ステッパーで表示するアイコンを変更する
     * @param props 
     * @returns 
     */
    const colorlibStepIcon = (props: StepIconProps) => {
        const stepIcons: { [index: string]: React.ReactElement } = {
            1: <SvgIcon fontSize='small'>
                <PackageIcon />
            </SvgIcon >,
            2: <SvgIcon fontSize='small'>
                <StoreClockIcon />
            </SvgIcon >,
            3: <SvgIcon fontSize='small'>
                <DroneIcon />
            </SvgIcon >,
            4: <DoneIcon fontSize='small' />,
        };

        if (props.active || props.completed) {
            return (
                <ActiveStepAvater>
                    {stepIcons[String(props.icon)]}
                </ActiveStepAvater>
            );
        } else {
            return (
                <UnfinishedStepAvater>
                    {stepIcons[String(props.icon)]}
                </UnfinishedStepAvater>
            );
        };
    };

    return (
        <Stepper
            activeStep={props.order?.receiveStatus ? steps.indexOf(props.order.receiveStatus) : undefined}
            alternativeLabel
            sx={{ margin: "16px 0px" }}>
            <Step>
                <StepLabel StepIconComponent={colorlibStepIcon}>集荷待ち</StepLabel>
            </Step>
            <Step>
                <StepLabel StepIconComponent={colorlibStepIcon}>配送準備中</StepLabel>
            </Step>
            <Step>
                <StepLabel StepIconComponent={colorlibStepIcon}>配送中</StepLabel>
            </Step>
            <Step>
                <StepLabel StepIconComponent={colorlibStepIcon}>配送完了</StepLabel>
            </Step>
        </Stepper>
    )
};