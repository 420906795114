import { FormControl, Paper } from '@mui/material';
import Button from '@mui/material/Button';
import { blue } from '@mui/material/colors';
import Grid from '@mui/material/Grid';
import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import ClearIcon from '@mui/icons-material/Clear';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import Alert from '@mui/material/Alert';
import { OrderInfo } from 'adoms-common-lib';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import ApplicationTooBar from '../../components/molecule/ApplicationToolBar';
import OrderDestinationInfo from '../../components/molecule/OrderDestinationInfo';
import OrderGoodsTable from '../../components/molecule/OrderGoodsTable';
import OrderStepBar, { Process } from '../../components/molecule/OrderStepBar';
import { APIConnector } from '../../connector/APIConnector';
import { CustomerURLInformationUtil } from '../../common/customerURLInformationUtil';


const useStyles = makeStyles()((theme) => ({
    griditem: {
    },
    table: {
        minWidth: 100,
    },
    paper: {
        padding: '12px',
        textAlign: 'center',
        height: '100%',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 32,
    },
    link: {
        textDecoration: 'none',
    },
    title: {
        flexGrow: 1,
        textAlign: 'center',
        backgroundColor: blue[500],
        color: 'white',
    },
    button: {
        margin: theme.spacing(1),
    }
}));




function createData(name: string, gram: number, count: number) {
    return { name, gram, count };
}


/**
 * 配送依頼を確定する
 */
export default function OrderGoodsConfirm() {
    const navigate = useNavigate();
    const { classes } = useStyles();
    const location = useLocation();
    //脆弱性対策のためシャローコピー
    const customerURLInformation = {...CustomerURLInformationUtil.instance.getCustomerURLInformation()};
    const order = location.state.order as OrderInfo;
    const qrCodeErrorList = location.state.qrCodeErrorList as string[] | undefined;
    const displayQrCodeScanResult = location.state.displayQrCodeScanResult as string[] | undefined;
    const imageBase64 = location.state.imageBase64 as string;
    const imageUrlFromState = location.state.imageUrl as string;
    //次へボタンをdiableにするかどうかのフラグ
    const [isDisableFlag, setDisableFlag] = React.useState(false);
    //エラーメッセージを表示するかどうかのフラグ
    const [isErrorFlag, setErrorFlag] = React.useState(false);
    const [image, setImage] = React.useState<string | undefined>(undefined);
    const [imageUrl, setImageUrl] = React.useState<string | undefined>(undefined);

    useEffect(() => {
        let fileReader = () => {
            if (typeof imageBase64 !== 'undefined') {
                setImage(imageBase64);
                setImageUrl(imageUrlFromState);
            }
        }

        if (customerURLInformation === null) {
            navigate("/forbidden403Page");
        }

        fileReader();
    }, []);

    /**
     * 次のページに遷移する際の処理（オーダー処理）
     * @param event 
     */
    const handleToNextPage = (event: any, order: OrderInfo) => {
        setDisableFlag(true);
        const fetchData = async () => {
            let tenantId = customerURLInformation.tenantId;
            console.log("customerURLInformation.urlId:" + customerURLInformation?.urlId);
            if (tenantId !== undefined) {
                let apigw: APIConnector = APIConnector.instance;
                let result: Promise<void | OrderInfo> = apigw.putOrder(order, tenantId, image);
                result.then((value: void | OrderInfo) => {
                    setDisableFlag(false);
                    navigate(
                        "/ordercomplete/" + customerURLInformation?.urlId,
                        {
                            state: { order: value as OrderInfo },
                        })
                }).catch((error) => {
                    setDisableFlag(false);
                    setErrorFlag(true);
                    console.log("エラー:" + error);
                });
            } else {
                setDisableFlag(false);
                setErrorFlag(true);
            }
        }
        fetchData();
    }

    /**
     * 注文情報の画像が入っているかどうかに応じ、画像出力をするか判断するハンドラ
     * @returns 注文情報の画像が入っている場合、プレビュー画面
     */
    const handleDisplayOrderImage = () => {
        if (typeof image !== 'undefined') {
            return (
                <Grid item xs={12} sm={12} className={classes.griditem}>
                    <Typography className={classes.title} variant="h6" component="div">
                        注文情報
                    </Typography>
                    <Paper className={classes.paper} elevation={0}>
                        <img
                            id="receiptImage"
                            src={imageUrl}
                            style={
                                navigator.userAgent.match(/iPhone|Android.+Mobile/) ?
                                    { width: '100%' } :
                                    { width: '50%' }
                            } />
                    </Paper>
                </Grid>
            );
        } else {
            return null;
        }
    }

    /**
     * OrderFlightSearchInputページに遷移する際の処理
     * @param event 
     */
    const handleToOrderFlightSearchInputPage = (event: any, displayQrCodeScanResult: string[] | undefined, order?: OrderInfo, qrCodeErrorList?: string[]) => {
        navigate(
            "/orderflightsearchinput/" + customerURLInformation?.urlId,
        {
            state: {
                orderForEdit: order,
                qrCodeErrorList: qrCodeErrorList,
                displayQrCodeScanResult: displayQrCodeScanResult
            }
        });
    };

    return (
        <div>
            <Grid container justifyContent="center" xs={12} spacing={1}>
                <Grid item xs={12} sm={12} >
                    <ApplicationTooBar label="配送依頼" />
                </Grid>
                <Grid item xs={12} sm={12} className={classes.griditem}>
                    <OrderStepBar process={Process.Confirm} />
                    {isErrorFlag ? (
                        <Alert severity="error">配送依頼中にエラーが発生しました。システム管理者へご連絡ください</Alert>
                    ) : (
                        <Alert severity="info">以下の内容で配送依頼します</Alert>
                    )}
                    {displayQrCodeScanResult ?
                        <Alert severity="success">{"QRコード読み取り結果"}<br />{displayQrCodeScanResult}</Alert>
                        : undefined
                    }
                </Grid>

                {/* <Typography className={classes.title} variant="h6" component="div">
                    外部注文情報
                </Typography>
                <Grid item xs={12} sm={12} className={classes.griditem}>
                    <Paper className={classes.paper} elevation={0}>
                        外部注文番号
                        <Typography variant="h5" component="div" color="secondary">
                            {order.externalOrderID !== undefined ? order.externalOrderID : "連携なし"}
                        </Typography>
                    </Paper>
                </Grid> */}

                <Typography className={classes.title} variant="h6" component="div">
                    配送希望時間
                </Typography>
                <Grid item xs={12} sm={12} className={classes.griditem}>
                    <Paper className={classes.paper} elevation={0}>
                        <Typography variant="h5" component="div" color="secondary">
                            {
                                order.preferredDeliveryDate !== '' &&
                                    order.preferredDeliveryTime !== '' ?
                                    `${order.preferredDeliveryDate} ${order.preferredDeliveryTime}` :
                                    "指定なし"
                            }
                        </Typography>
                    </Paper>
                </Grid>

                <Typography className={classes.title} variant="h6" component="div">
                    荷物の受取人
                </Typography>
                <Grid item xs={12} sm={12} className={classes.griditem}>
                    <OrderDestinationInfo order={order} />
                </Grid>

                <Grid item xs={12} sm={12} className={classes.griditem}>
                    <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                        店舗担当者連絡先
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} className={classes.griditem}>
                    <Paper className={classes.paper} elevation={0}>
                        <Typography variant="body1" component="div">
                            電話番号：{order.destination.tel2}
                        </Typography>
                    </Paper>
                </Grid>

                <Typography className={classes.title} variant="h6" component="div">
                    発着ポート
                </Typography>
                <Grid item xs={12} sm={12} className={classes.griditem}>
                    <Paper className={classes.paper} elevation={0}>
                        <Typography variant="body1" component="div">
                            {order.departure.name} ⇒ {order.arrival.name}
                        </Typography>
                    </Paper>
                </Grid>

                {handleDisplayOrderImage()}

                <Typography className={classes.title} variant="h6" component="div">
                    配送物
                </Typography>
                <Grid item xs={12} sm={12} className={classes.griditem}>
                    <OrderGoodsTable order={order} />
                </Grid>



                <Grid item xs={4} sm={4} className={classes.griditem}>
                    <Paper className={classes.paper} elevation={0}>
                        <FormControl variant="standard" className={classes.formControl}>
                            <Button
                                className={classes.button}
                                color="primary"
                                aria-label="menu"
                                endIcon={<ClearIcon />}
                                onClick={(e) => { handleToOrderFlightSearchInputPage(e, undefined) }}>
                                <Typography>取り消し</Typography>
                            </Button>
                        </FormControl>
                    </Paper>
                </Grid>
                <Grid item xs={4} sm={4} className={classes.griditem}>
                    <Paper className={classes.paper} elevation={0}>
                        <FormControl variant="standard" className={classes.formControl}>
                            <Button
                                className={classes.button}
                                color="primary"
                                aria-label="menu"
                                endIcon={<EditIcon />}
                                onClick={(e) => { handleToOrderFlightSearchInputPage(e, displayQrCodeScanResult, order, qrCodeErrorList) }}>
                                <Typography>修正</Typography>
                            </Button>
                        </FormControl>
                    </Paper>
                </Grid>
                <Grid item xs={4} sm={4} className={classes.griditem}>
                    <Paper className={classes.paper} elevation={0}>
                        <FormControl variant="standard" className={classes.formControl}>
                            <Button
                                className={classes.button}
                                color="secondary"
                                aria-label="menu"
                                disabled={isDisableFlag}
                                endIcon={<DoneIcon />}
                                onClick={(e) => { handleToNextPage(e, order) }}>
                                <Typography>確定</Typography>
                            </Button>
                        </FormControl>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    );
}