import { CustomerURLInformation } from "adoms-common-lib";
import { APIConnector } from "../connector/APIConnector";

/**
 * シングルトンアーキテクチャで、一度取得した顧客情報を保持するためのクラス
 */
export class CustomerURLInformationUtil {
    private static _instance: CustomerURLInformationUtil;
    private customerURLInformation: CustomerURLInformation | undefined;
    private apiConnecter: APIConnector;

    private constructor() {
        this.apiConnecter = APIConnector.instance;
    }

    /** インスタンスの取得 */
    public static get instance(): CustomerURLInformationUtil {
        if (!this._instance) {
            this._instance = new CustomerURLInformationUtil();
        }

        // 生成済みのインスタンスを返す
        return this._instance;
    }

    /**
     * APIからCustomerURLInformationを取得する 
     * @param urlId: クライアントが入力したURLID
     * @returns 取得したCustomerURLInformation。ない場合はundefined
     */
    public async updateCustomerURLInformationByUrlId(urlId: string): Promise<CustomerURLInformation | undefined> {
        if (this.customerURLInformation !== undefined && this.customerURLInformation?.urlId !== urlId) {
            return undefined;
        } else if (this.customerURLInformation?.urlId === urlId) {
            return this.customerURLInformation;
        }

        return await this.apiConnecter.getCustomerURLInformation(urlId).then((response) => {
            this.customerURLInformation = response;
            return response
        }).catch(error => {
            console.log(error);
            return undefined;
        })
    }

    /**
     * インスタンスに格納されたCustomerURLInformationを取得する
     * @returns インスタンスに格納されたCustomerURLInformation
     */
    public getCustomerURLInformation(): CustomerURLInformation | undefined {
        return this.customerURLInformation;
    }

    /**
     * インスタンスに格納されたCustomerURLInformationを削除する
     * 取得したCustomerURLInformationが必要なくなった時に呼び出す
     */
    public deleteCustomerURLInformation(): void {
        this.customerURLInformation = undefined;
    }
};