import { FormControl, InputLabel, Paper, Select, Table, TableBody, TableCell, TableContainer, TableRow, TextField } from '@mui/material';
import { blue } from '@mui/material/colors';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Alert } from '@mui/material';
import { GoodsInfo, MemberType, OrderInfo } from 'adoms-common-lib';
import React, { useEffect } from 'react';
import { NumericFormat, PatternFormat } from 'react-number-format';
import { useLocation, useNavigate } from 'react-router-dom';
import { imageResizeAndConvertBase64 } from '../../common/ImageEditUtil';
import { ImageStoreButton } from '../../components/atoms/ImageStoreButton';
import ApplicationTooBar from '../../components/molecule/ApplicationToolBar';
import OrderStepBar, { Process } from '../../components/molecule/OrderStepBar';
import { CustomerURLInformationUtil } from '../../common/customerURLInformationUtil';

const useStyles = makeStyles()((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    formControl: {
        margin: theme.spacing(1),
        alignItems: 'left',
        minWidth: 32,
    },
    paper: {
        padding: '12px',
        textAlign: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
    },
    title: {
        flexGrow: 1,
        textAlign: 'center',
        backgroundColor: blue[500],
        color: 'white',
    },
    name: {
        width: 180,
    },
    griditem: {
    },
    table: {
        minWidth: 120,
    },
    tel: {
        width: 180,
    },
    zipcode: {
        width: 140,
    },
    goodsweight: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 100,
    },
    alert: {
        marginBottom: theme.spacing(1),
        textAlign: "left"
    },
    paperAlert: {
        padding: theme.spacing(1),
    },
    paperSuccess: {
        padding: theme.spacing(1),
        marginTop: theme.spacing(0.5)
    },
    qrResult: {
        textAlign: "left"
    }
}));



interface NumberFormatCustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

/**
 * 電話番号のフォーマット
 * @param props 
 */
const TelNumberFormat = React.forwardRef(function NumberFormatCustom(
    props: NumberFormatCustomProps,
    ref,
) {
    const { onChange, ...other } = props;

    return (
        <PatternFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            valueIsNumericString
            format="###########"
        />
    );
});

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(
    props: NumberFormatCustomProps,
    ref,
) {
    const { onChange, ...other } = props;

    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            defaultValue={0}
            inputMode='decimal'
            thousandSeparator
            valueIsNumericString
            allowNegative={false}
            suffix="g"
        />
    );
});


export default function OrderGoodAndDestinationInput() {
    const navigate = useNavigate();
    const { classes } = useStyles();
    const maxWeight = 2500;
    const location = useLocation();
    const customerURLInformation = CustomerURLInformationUtil.instance.getCustomerURLInformation();
    const order = location.state?.order as OrderInfo;
    const orderForEdit = location.state?.orderForEdit as OrderInfo;
    const qrCodeErrorList = location.state?.qrCodeErrorList as string[];
    const displayQrCodeScanResult = location.state?.displayQrCodeScanResult as string;

    const [firstname, setFirstName] = React.useState(orderForEdit?.destination?.firstname ? orderForEdit.destination.firstname : "");
    const [lastname, setLastName] = React.useState(orderForEdit?.destination?.lastname ? orderForEdit.destination.lastname : "");
    const [tel, setTel] = React.useState(orderForEdit?.destination?.tel ? orderForEdit.destination.tel : "");
    const [tel2, setTel2] = React.useState(orderForEdit?.destination?.tel2 ? orderForEdit.destination.tel2 : "");
    const [prefecture, setPrefecture] = React.useState(orderForEdit?.destination?.prefecture ? orderForEdit.destination.prefecture : order.arrival.inputDefaultPrefecture);
    const [address, setAddress] = React.useState(orderForEdit?.destination?.address ? orderForEdit.destination.address : order.arrival.inputDefaultCity + order.arrival.inputDefaultAddress);
    const [imageFile, setImageFile] = React.useState<File | undefined>();
    const [inputWeight, setInputWeight] = React.useState<number | undefined>(orderForEdit?.goods[0]?.weight ? orderForEdit.goods[0]?.weight : undefined);
    //初期値 五島向けに初期値と荷物名変更（固定）
    const [goodsInfo, setGoodsInfo] = React.useState<GoodsInfo>(orderForEdit?.goods[0] ? orderForEdit.goods[0] : { weight: 0, name: '荷物一式', count: 1 });
    const [totalWeightString, setTotalWeightString] = React.useState("0");
    const [remainWeightString, setRemainWeightString] = React.useState(new Intl.NumberFormat().format(maxWeight));
    const [remainWeightNum, setRemainWeightNum] = React.useState(0);

    useEffect(() => {
        if (customerURLInformation === null) {
            navigate("/forbidden403Page");
        }
    }, []);

    const handleOnChangeFirstName = (event: any) => {
        setFirstName(event.target.value);
    }

    const handleOnChangeLastName = (event: any) => {
        setLastName(event.target.value);
    }

    const handleOnChangeTel = (event: any) => {
        setTel(event.target.value);
    }

    const handleOnChangeTel2 = (event: any) => {
        setTel2(event.target.value);
    }

    const handleOnChangePrefecture = (event: any) => {
        setPrefecture(event.target.value);
    }

    const handleOnChangeAddress = (event: any) => {
        setAddress(event.target.value);
    }

    /**
    * 重量が変更されたときのハンドラ
     * @param event 
     */
    const handleChangeWeight = (event: React.ChangeEvent<HTMLInputElement>) => {
        let weight = Number(event.target.value);
        setInputWeight(weight);
        goodsInfo.weight = weight;
        setGoodsInfo(goodsInfo);
        //数字3桁カンマ区切りのフォーマット
        const nf: Intl.NumberFormat = new Intl.NumberFormat();

        // 合計重量
        setTotalWeightString(nf.format(weight));

        //残重量
        setRemainWeightNum(maxWeight - weight);
        setRemainWeightString(nf.format(maxWeight - weight));
    };

    function isNextDisable(): boolean {
        if (
            firstname !== ""
            && lastname !== ""
            // && remainWeightNum >= 0
            // && isWeightTextFieldHighRight === false 
            // && tel !== "" 
            // && tel2 !== ""
        ) {
            return false;
        } else {
            return true;
        }
    }

    /**
     * 次のページに遷移する際の処理
     * @param event 
     * @param navigate 
     */
    const handleToNextPage = async (event: any, order: OrderInfo) => {
        //非会員として登録
        order.goods = [goodsInfo];
        order.destination.type = MemberType.Guest;
        order.destination.id = "empty";
        order.destination.firstname = firstname;
        order.destination.lastname = lastname;
        order.destination.address = address;
        order.destination.zipcode = "***";
        order.destination.prefecture = prefecture;
        order.destination.tel = tel;
        order.destination.tel2 = tel2;

        /*
        navigateのstateとしてFileオブジェクトを格納できないため、
        予めBase64形式に変換しておく。
        */
        let imageDataForSend = await new Promise<
            { imageBase64: string, imageUrl: string } | undefined
        >((resolve, reject) => {
            if (typeof imageFile !== 'undefined') {
                return resolve(imageResizeAndConvertBase64(imageFile));
            } else {
                return resolve(undefined);
            }
        }).catch(err => {
            let error = err as Error;
            console.log(error);
            throw error;
        });

        navigate(
            "/orderconfirm/" + customerURLInformation?.urlId,
            {
                state: {
                    order: order,
                    imageBase64: imageDataForSend?.imageBase64 ?? undefined,
                    imageUrl: imageDataForSend?.imageUrl ?? undefined,
                    displayQrCodeScanResult: displayQrCodeScanResult,
                    qrCodeErrorList: qrCodeErrorList
                },
            })
    }

    const colItemStyle = { width: '25%', padding: '0' };
    const colValueStyle = { width: '75%', padding: '0', verticalAlign: 'middle' };

    /**
     * 前のページに遷移する際の処理
     * @param event 
     * @param navigate 
     */
    const handleToBackPage = async (event: any, order: OrderInfo) => {
        //非会員として登録
        order.goods = [goodsInfo];
        order.destination.type = MemberType.Guest;
        order.destination.id = "empty";
        order.destination.firstname = firstname;
        order.destination.lastname = lastname;
        order.destination.address = address;
        order.destination.zipcode = "***";
        order.destination.prefecture = prefecture;
        order.destination.tel = tel;
        order.destination.tel2 = tel2;

        navigate(
            "/orderflightsearchinput",
            {
                state: {
                    orderForEdit: order,
                    displayQrCodeScanResult: displayQrCodeScanResult,
                    qrCodeErrorList: qrCodeErrorList
                },
            });
    };

    return (
        <div>
            <Grid container justifyContent="center" xs={12} spacing={1}>
                <Grid item xs={12} sm={12} >
                    <ApplicationTooBar label="配送依頼" />
                </Grid>

                <Grid item xs={12} sm={12} className={classes.griditem}>
                    <OrderStepBar process={Process.DestinationInput} />
                    {(qrCodeErrorList && qrCodeErrorList.length !== 0) || displayQrCodeScanResult ?
                        <Paper className={classes.paperAlert} elevation={0}>
                            {qrCodeErrorList?.map((qrCodeError, index) => (
                                <Alert
                                    severity="error"
                                    className={classes.alert}>
                                    {qrCodeError}
                                </Alert>
                            ))}
                            <Alert severity="success"
                                className={classes.qrResult}>{"QRコード読み取り結果"}<br />{displayQrCodeScanResult}</Alert>
                        </Paper>
                        : undefined
                    }
                </Grid>
                <Grid item xs={12} sm={12} className={classes.griditem}>
                    <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                        配送物重量
                    </Typography>
                </Grid>
                <Grid key={0} item xs={12} sm={12} className={classes.griditem}>
                    <Paper className={classes.paper} elevation={0}>
                        <FormControl variant="standard" className={classes.formControl}>
                            <TextField
                                className={classes.goodsweight}
                                label="重量"
                                onChange={(e: any) => handleChangeWeight(e)}
                                name="weight"
                                id="formatted-numberformat-input"
                                value={inputWeight}
                                variant="outlined"
                                InputProps={{
                                    inputComponent: NumberFormatCustom as any,
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </FormControl>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Paper className={classes.paper} elevation={0}>
                        <Typography color={remainWeightNum >= 0 ? "inherit" : "error"}>合計重量：{totalWeightString} g</Typography>
                    </Paper>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Paper className={classes.paper} elevation={0}>
                        <Typography color={remainWeightNum >= 0 ? "inherit" : "error"}>配送可能な残重量：{remainWeightString} g</Typography>
                    </Paper>
                </Grid>

                <Grid item xs={12} sm={12} className={classes.griditem}>
                    <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                        荷物を受け取る方の情報
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} className={classes.griditem}>
                    <TableContainer component={Paper} elevation={0}>
                        <Table className={classes.table} size="small" aria-label="simple table">
                            <TableBody>
                                <TableRow>
                                    <TableCell align="right" component="th" scope="" style={colItemStyle}>お名前</TableCell>
                                    <TableCell align="left" style={colValueStyle}>
                                        <FormControl variant="standard" className={classes.formControl} style={{ padding: 1 }}>
                                            <Grid container justifyContent="center" xs={12} style={{ marginRight: 15 }} spacing={0}>
                                                <Grid item xs={12} sm={6} className={classes.griditem}>
                                                    <TextField
                                                        error={!lastname}
                                                        className={classes.name}
                                                        label="姓"
                                                        onChange={(e) => handleOnChangeLastName(e)}
                                                        name="lastname"
                                                        id="destination-lastname"
                                                        variant="outlined"
                                                        margin="dense"
                                                        value={lastname} />
                                                </Grid>
                                                <Grid item xs={12} sm={6} className={classes.griditem}>
                                                    <TextField
                                                        error={!firstname}
                                                        className={classes.name}
                                                        label="名"
                                                        onChange={(e) => handleOnChangeFirstName(e)}
                                                        name="firstName"
                                                        id="destination-first"
                                                        variant="outlined"
                                                        margin="dense"
                                                        value={firstname} />
                                                </Grid>
                                            </Grid>
                                        </FormControl>

                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="right" component="th" scope="" style={colItemStyle}>
                                        電話番号
                                    </TableCell>
                                    <TableCell align="left" component="td" style={colValueStyle} valign="middle">
                                        <FormControl
                                            variant="standard"
                                            className={classes.formControl}
                                            style={{ marginRight: 0 }}>
                                            <TextField
                                                className={classes.tel}
                                                onChange={(e) => handleOnChangeTel(e)}
                                                name="tel"
                                                label="例）09011112222"
                                                margin="dense"
                                                id="destination-tel-first"
                                                variant="outlined"
                                                helperText={<span>ハイフン（-）不要<br />携帯、ない場合は固定電話</span>}
                                                value={tel}
                                                InputProps={{
                                                    inputComponent: TelNumberFormat as any,
                                                }} />
                                        </FormControl>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="right" component="th" scope="" style={colItemStyle}>都道府県</TableCell>
                                    <TableCell align="left" style={colValueStyle}>
                                        <FormControl className={classes.formControl}>
                                            <TextField
                                                label="都道府県"
                                                margin="dense"
                                                onChange={(e) => handleOnChangePrefecture(e)}
                                                name="prefecture"
                                                id="destination-prefecture"
                                                variant="outlined"
                                                value={prefecture} />
                                        </FormControl>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="right" component="th" scope="" style={colItemStyle}>住所</TableCell>
                                    <TableCell align="left" style={colValueStyle}>
                                        <FormControl className={classes.formControl}>
                                            <TextField
                                                label="住所"
                                                margin="dense"
                                                onChange={(e) => handleOnChangeAddress(e)}
                                                name="address"
                                                id="destination-address"
                                                variant="outlined"
                                                value={address} />
                                        </FormControl>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="right" component="th" scope="" style={colItemStyle}>注文内容<br /></TableCell>
                                    <TableCell style={{ padding: 8 }}>
                                        <div style={{ width: '75%', wordBreak: 'break-all' }}>
                                            {imageFile !== undefined ? imageFile.name : ""}
                                        </div>
                                        <ImageStoreButton
                                            inputFile={imageFile}
                                            setImageFile={setImageFile}
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>


                <Grid item xs={12} sm={12} className={classes.griditem}>
                    <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                        店舗担当者連絡先の情報
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={12} className={classes.griditem}>
                    <TableContainer component={Paper} elevation={0}>
                        <Table className={classes.table} size="small" aria-label="simple table">
                            <TableBody>
                                <TableRow>
                                    <TableCell align="right" component="th" scope="" style={colItemStyle}>
                                        電話番号<br />(携帯)
                                    </TableCell>
                                    <TableCell align="left" component="td" style={colValueStyle} valign="middle">
                                        <FormControl
                                            variant="standard"
                                            className={classes.formControl}
                                            style={{ marginRight: 0 }}>
                                            <TextField
                                                className={classes.tel}
                                                onChange={(e) => handleOnChangeTel2(e)}
                                                name="tel2"
                                                label="例）09011112222"
                                                margin="dense"
                                                id="destination-tel-first"
                                                variant="outlined"
                                                helperText="ハイフン（-）不要"
                                                value={tel2}
                                                InputProps={{
                                                    inputComponent: TelNumberFormat as any,
                                                }} />
                                        </FormControl>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

                <Grid item xs={6} sm={6} className={classes.griditem}>
                    <Paper className={classes.paper} elevation={0} >
                        <FormControl variant="standard" className={classes.formControl}>
                            {orderForEdit ?
                                <IconButton
                                    edge="start"
                                    color="primary"
                                    aria-label="menu"
                                    onClick={(e) => { handleToBackPage(e, orderForEdit) }}
                                    size="large">
                                    <ArrowBackIosIcon /><Typography>戻る</Typography>
                                </IconButton>
                                : undefined
                            }
                            {order ?
                                <IconButton
                                    edge="start"
                                    color="primary"
                                    aria-label="menu"
                                    onClick={(e) => { handleToBackPage(e, order) }}
                                    size="large">
                                    <ArrowBackIosIcon /><Typography>戻る</Typography>
                                </IconButton>
                                : undefined
                            }
                        </FormControl>
                    </Paper>
                </Grid>

                <Grid item xs={6} sm={6} className={classes.griditem}>
                    <Paper className={classes.paper} elevation={0}>
                        <FormControl variant="standard" className={classes.formControl}>
                            {orderForEdit ?
                                <IconButton
                                    edge="start"
                                    color="primary"
                                    aria-label="menu"
                                    onClick={(e) => { handleToNextPage(e, orderForEdit) }}
                                    disabled={isNextDisable()}
                                    size="large">
                                    <Typography>次へ</Typography><ArrowForwardIosIcon />
                                </IconButton>
                                : undefined
                            }
                            {order ?
                                <IconButton
                                    edge="start"
                                    color="primary"
                                    aria-label="menu"
                                    onClick={(e) => { handleToNextPage(e, order) }}
                                    disabled={isNextDisable()}
                                    size="large">
                                    <Typography>次へ</Typography><ArrowForwardIosIcon />
                                </IconButton>
                                : undefined
                            }
                        </FormControl>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    );
}